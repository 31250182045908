.shadowBoxWrapper {
	box-shadow: 0px 6px 20px -7px #000000ba;
	background-color: rgb(0, 0, 0);
}

.shadowBoxWrapper .shadowBox {
	position: absolute;
	background-color: transparent;
	width: 42%;
	height: 20px;
	bottom: 30px;
	box-shadow: 0px 27px 18px 10px #0000008f;
}

.shadowBoxWrapper .shadowBox.left {
	left: 5%;
	transform: rotate(-6deg);
}

.shadowBoxWrapper .shadowBox.right {
	right: 5%;
	transform: rotate(6deg);
}
