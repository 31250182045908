.productForm label {
	font-weight: bolder;
	font-weight: bold;
	font-family: "Darker Grotesque", sans-serif;
	color: #000000;
}

.text-input {
	width: 100%;
	min-width: 0px;
	outline: 2px solid transparent;
	outline-offset: 2px;
	position: relative;
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	appearance: none;
	transition-property: var(--chakra-transition-property-common);
	transition-duration: var(--chakra-transition-duration-normal);
	font-size: var(--chakra-fontSizes-md);
	-webkit-padding-start: var(--chakra-space-4);
	padding-inline-start: var(--chakra-space-4);
	-webkit-padding-end: var(--chakra-space-4);
	padding-inline-end: var(--chakra-space-4);
	height: var(--chakra-sizes-10);
	border-radius: var(--chakra-radii-md);
	border: 1px solid;
	border-color: inherit;
	background: inherit;
}
