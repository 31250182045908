body {
  margin: 0;
  font-family: 'Roboto',-apple-system, BlinkMacSystemFont, 'Segoe UI',  'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.border-black{
  border: 2px solid black;
}
.border-black>div:not(:first-child){
  border-top: 2px solid black;
}

.product-description p{
  margin-bottom: 20px;
}

.product-description h2,.product-description h3{
  font-family: var(--chakra-fonts-heading);
  font-weight: var(--chakra-fontWeights-bold);
  font-size: var(--chakra-fontSizes-xl);
  line-height: 1.33;
  margin-bottom: 0.7em;
}