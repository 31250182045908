* {
	--scrollbar-color: #000000;
}

a[href] {
	color: var(--chakra-colors-lightBlue);
}

body {
	max-height: 100svh !important;
}

ul {
	padding-left: 1.3em !important;
}

label span {
	font-size: small !important;
}

.headingLine {
	overflow: hidden;
	text-align: center;
	font-weight: bold;
	font-family: "Darker Grotesque", sans-serif;
}

.headingLine:before,
.headingLine:after {
	background-color: var(--chakra-colors-chakra-border-color);
	content: "";
	display: inline-block;
	height: 1px;
	position: relative;
	vertical-align: middle;
	width: 50%;
}

.headingLine:before {
	right: 0.5em;
	margin-left: -50%;
}

.headingLine:after {
	left: 0.5em;
	margin-right: -50%;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: var(--scrollbar-color) #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 4px;
}

*::-webkit-scrollbar-track {
	background: #ffffff;
}

*::-webkit-scrollbar-thumb {
	background-color: var(--scrollbar-color);
	border-radius: 0px;
	/* border: 2px solid #ffffff; */
}

.grow-on-hover:hover {
	transition: transform 0.3s ease;
	transform: scale(1.03);
	box-shadow: var(--chakra-shadows-base);
}

.shake-vertical {
	-webkit-animation: shake-vertical 5s cubic-bezier(0.455, 0.03, 0.515, 0.955)
		infinite both;
	animation: shake-vertical 5s cubic-bezier(0.455, 0.03, 0.515, 0.955)
		infinite both;
}

@-webkit-keyframes shake-vertical {
	0%,
	35%,
	65%,
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	45% {
		-webkit-transform: translateY(-8px);
		transform: translateY(-8px);
	}

	55% {
		-webkit-transform: translateY(8px);
		transform: translateY(8px);
	}
}
@keyframes shake-vertical {
	0%,
	35%,
	65%,
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	45% {
		-webkit-transform: translateY(-8px);
		transform: translateY(-8px);
	}

	55% {
		-webkit-transform: translateY(8px);
		transform: translateY(8px);
	}
}

.masked-image {
	mask: url("data:image/svg+xml;base64,PCEtLT94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/LS0+CiAgICAgICAgICAgICAgPHN2ZyBpZD0ic3ctanMtYmxvYi1zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSI+CiAgICAgICAgICAgICAgICAgICAgPGRlZnM+IAogICAgICAgICAgICAgICAgICAgICAgICA8bGluZWFyR3JhZGllbnQgaWQ9InN3LWdyYWRpZW50IiB4MT0iMCIgeDI9IjEiIHkxPSIxIiB5Mj0iMCI+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8c3RvcCBpZD0ic3RvcDEiIHN0b3AtY29sb3I9InJnYmEoMjQ4LCAxMTcsIDU1LCAxKSIgb2Zmc2V0PSIwJSI+PC9zdG9wPgogICAgICAgICAgICAgICAgICAgICAgICAgICAgPHN0b3AgaWQ9InN0b3AyIiBzdG9wLWNvbG9yPSJyZ2JhKDI1MSwgMTY4LCAzMSwgMSkiIG9mZnNldD0iMTAwJSI+PC9zdG9wPgogICAgICAgICAgICAgICAgICAgICAgICA8L2xpbmVhckdyYWRpZW50PgogICAgICAgICAgICAgICAgICAgIDwvZGVmcz4KICAgICAgICAgICAgICAgIDxwYXRoIGZpbGw9InVybCgjc3ctZ3JhZGllbnQpIiBkPSJNMTcuOSwtMzMuMkMyMy4yLC0yOC4xLDI3LjMsLTIzLjEsMjkuOCwtMTcuNkMzMi4zLC0xMi4xLDMzLjIsLTYsMzMuNSwwLjJDMzMuOCw2LjQsMzMuNSwxMi44LDMyLDIwQzMwLjQsMjcuMSwyNy43LDM1LjEsMjIuMSwzOS4zQzE2LjYsNDMuNCw4LjMsNDMuNywwLjQsNDMuMUMtNy42LDQyLjUsLTE1LjEsNDAuOSwtMjEuOCwzNy40Qy0yOC41LDMzLjksLTM0LjMsMjguNSwtMzYuNiwyMkMtMzguOCwxNS40LC0zNy42LDcuNywtMzYuOCwwLjRDLTM2LjEsLTYuOSwtMzUuOSwtMTMuOCwtMzMuNSwtMjAuMUMtMzEuMSwtMjYuNCwtMjYuNSwtMzIuMSwtMjAuNSwtMzYuOEMtMTQuNiwtNDEuNSwtNy4zLC00NS4yLC0wLjUsLTQ0LjRDNi40LC00My42LDEyLjcsLTM4LjMsMTcuOSwtMzMuMloiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUwIDUwKSIgc3Ryb2tlLXdpZHRoPSIwIiBzdHlsZT0idHJhbnNpdGlvbjogYWxsIDAuM3MgZWFzZSAwczsiPjwvcGF0aD4KICAgICAgICAgICAgICA8L3N2Zz4=");
	mask-repeat: no-repeat;
}

.decorative {
	font-family: "Alex Brush" !important;
	font-size: 2.1em !important;
	font-weight: 500 !important;
	opacity: 0.9;
}
